.packages-based-section {
    background: url("../../public/svg/home/packages-based/curve-line.svg")
        no-repeat bottom center #f8eae6;
    .packages-based-container {
        background-image: url("../../public/svg/home/packages-based/camera.svg"),
            url("../../public/svg/home/packages-based/aeroplane.svg"),
            url("../../public/svg/home/packages-based/pointer.svg");
        background-position: right 20px bottom -20px, left 200px top,
            right 150px top;
        background-repeat: no-repeat;
        @media only screen and (max-width: 1279px) {
            background-position: right 20px bottom -20px, left 45px top,
                right 45px top;
        }
        @media only screen and (max-width: 1023px) {
            background-position: right 20px bottom -20px, left 25px top,
                right 25px top;
        }
        @media only screen and (max-width: 767px) {
            background-image: initial;
        }
    }
    .packages-based-slider {
        .swiper-pagination-wrapper {
            .pagination-arrows {
                figure {
                    background-color: #fff3ed;
                    &:hover {
                        background-color: #ff4514;
                    }
                }
            }
        }
    }
    .packages-swiper {
        a {
            background-color: #fff;
            border-radius: 14px;
            width: 100%;
            figure {
                position: relative;
                border-radius: 14px;
                overflow: hidden;
                > img {
                    width: 100%;
                    height: 220px;
                    object-fit: cover;
                }
            }
            .packages-info {
                border-top: #e9dad4 dotted 1px;
                position: relative;
                span {
                    color: #3d4152;
                }
                &:before,
                &:after {
                    content: "";
                    position: absolute;
                    background-color: #f8eae6;
                    width: 22px;
                    height: 22px;
                    border-radius: 50%;
                    top: -12px;
                }
                &:before {
                    left: -36px;
                }
                &:after {
                    right: -36px;
                }
            }
            &:hover {
                figure {
                    figcaption {
                        opacity: 1;
                    }
                }
                .packages-info {
                    color: #ff4514;
                }
            }
        }
        .card-width {
            max-width: 237px;
            width: 100%;

            a.inline-flex{
                figure{
                    > img{
                        height: 178px;
                    }
                }
            }

            @media only screen and (max-width: 1024px) {
                max-width: 332px;
            }

            @media only screen and (max-width: 767px) {
                max-width: 400px;
            }
        }
            @media (min-width:1200px) and (max-width: 1399px) {
                .card-width {
                max-width: 257px;
            }
            
        }
    }
    @media only screen and (max-width: 767px) {
        background-image: initial;
    }
}
